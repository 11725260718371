import * as THREE from 'three'
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
const gui = new dat.GUI()


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()


/**
 * Fonts
 */

const fontLoader = new FontLoader()
let text = new THREE.Mesh();
let pivot = new THREE.Group()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(
            'David McAlle', 
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        const matcapTexture = textureLoader.load('/textures/matcaps/7.png')
        const textMaterial = new THREE.MeshMatcapMaterial({matcap: matcapTexture})
        const sphereMaterial = new THREE.MeshMatcapMaterial({matcap: matcapTexture})
        const torusMaterial = new THREE.MeshMatcapMaterial({matcap: matcapTexture})
        const cubeMaterial = new THREE.MeshMatcapMaterial({matcap: matcapTexture})
        textGeometry.computeBoundingBox()
        textGeometry.translate(
            - (textGeometry.boundingBox.max.x - 0.02) * 0.5, 
            - (textGeometry.boundingBox.max.z - 0.02) * 0.5,
            - (textGeometry.boundingBox.max.z - 0.03) * 0.5
        )
        textGeometry.center()
        console.log(textGeometry.boundingBox)
        text = new THREE.Mesh(textGeometry, textMaterial)
        
        // Create the pivot point
        pivot = new THREE.Group();
        pivot.add(text);
        scene.add(pivot);

        // Generate random shapes
        const shapes = ['Torus', 'Sphere', 'Box'];
        for(let i = 0; i < 1000; i++) {
            let geometry;
            const shapeType = shapes[Math.floor(Math.random() * shapes.length)];
            let material;
            switch(shapeType) {
                case 'Torus':
                    geometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);
                    material = torusMaterial;
                    break;
                case 'Sphere':
                    geometry = new THREE.SphereGeometry(0.3, 32, 32);
                    material = sphereMaterial;
                    break;
                case 'Box':
                    geometry = new THREE.BoxGeometry(0.3, 0.3, 0.3);
                    material = cubeMaterial;
                    break;
            }

            const shape = new THREE.Mesh(geometry, material);
            shape.position.set(
                (Math.random() - 0.5) * 20,
                (Math.random() - 0.5) * 20,
                (Math.random() - 0.5) * 20
            );
            
            // Add random rotations
            shape.rotation.x = Math.random() * Math.PI;
            shape.rotation.y = Math.random() * Math.PI;

            // Add random scaling
            const scale = Math.random();
            shape.scale.set(scale, scale, scale);

            pivot.add(shape);
        }
    }
)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
const mouse = new THREE.Vector2();
window.addEventListener('mousemove', (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
});

const cameraBounds = {
    maxUp: 5,
    maxDown: -5,
    maxLeft: -5,
    maxRight: 5
  };
  
const cameraFolder = gui.addFolder('Camera Bounds');
cameraFolder.add(cameraBounds, 'maxUp', -10, 10);
cameraFolder.add(cameraBounds, 'maxDown', -10, 10);
cameraFolder.add(cameraBounds, 'maxLeft', -10, 10);
cameraFolder.add(cameraBounds, 'maxRight', -10, 10);

let mouseX = 0;
let mouseY = 0;
const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;

document.addEventListener('mousemove', (event) => {
    mouseX = (event.clientX - windowHalfX) / windowHalfX;
    mouseY = (event.clientY - windowHalfY) / windowHalfY;
}, false);

const guiState = {
    minCameraDistance: 1
  };
  
gui.add(guiState, 'minCameraDistance').min(0.1).max(1).step(0.1);
  

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    text.rotation.z = Math.sin(elapsedTime) * 0.2;

    if (pivot) {
        pivot.rotation.y = Math.sin(elapsedTime) * 0.4;
        pivot.rotation.z = Math.sin(elapsedTime) * 0.2;
        pivot.rotation.x = Math.sin(elapsedTime) * 0.1;
    }
  
    // Calculate distance from center based on mouse position
    const distanceFromCenter = Math.sqrt(mouseX * mouseX + mouseY * mouseY);
    const cameraDistance = guiState.minCameraDistance + 10 * Math.pow(distanceFromCenter, 2);  // Multiplier changed

    // Update camera position
    camera.position.x = cameraDistance * Math.sin(mouseX * Math.PI);
    camera.position.y = cameraDistance * Math.sin(mouseY * Math.PI);
    camera.position.z = cameraDistance * Math.cos(mouseX * Math.PI) * Math.cos(mouseY * Math.PI);

    // Make sure the camera is always looking at the center
    camera.lookAt(0, 0, 0);

    // Update controls
    controls.update();
  
    // Render
    renderer.render(scene, camera);
  
    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };
  


tick()